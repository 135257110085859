import ReactGA from 'react-ga';

/**
 * Constant for the width of the Menu
 */
export const MENU_WIDTH = '240px';

export const gaEvent = (category, action) => ReactGA.event({ category, action });

export const trackBookstore = (bookstoreName, social) => gaEvent('Bookstore', `Clicked "${ social }" from "${ bookstoreName }"`);

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * Remove time zone from date string
 * @param {string} date - The date that needs to be updated.
 */
export const transformDate = date => date.split('T')[0].replace(/-/g, '/');

/**
 * Remove time zone from date string and create a more readable date
 * @param {string} date - The date that needs to be updated.
 * @param {function} t - The translation function.
 */
export const formatDate = (date, t) => {
  let fullDate = new Date( transformDate(date) );
  return `${ t(`global.months.${ fullDate.getMonth() }`) } ${ fullDate.getDate() }, ${ fullDate.getFullYear() }`;
};

export const DEFAULT_SNACKBAR = { show: false, autoHideDuration: 2000, severity: 'success', message: '' };

/**
 * Handle endpoint errors
 * @param {Object} state - The current state that you want to check.
 * @param {Object} props - The props of the page.
 * @param {function} setSnackbar - The action to call the snackbar component.
 * @param {function} t - The translation function.
 */
export const handleEndpointErrors = (state, props, setSnackbar, t) => {
  if (state.error.status === 401) {
    props.logout(true, true);
  } else {
    setSnackbar({ show: true, severity: 'error', message: t('global.errors.endpoint') });
  }
}
