import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Masonry from 'react-masonry-css';

import CustomCard from './CustomCard';

const useStyles = makeStyles(theme => ({
  root: {},
  masonryGrid: {
    display: 'flex',
    marginLeft: theme.spacing(-1),
    width: '100%',
  },
  masonryGridColumn: {
    paddingLeft: theme.spacing(2),
    backgroundClip: 'padding-box',
    '& > div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const MasonryLayout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { bookstores, locations } = props;

  const breakpointCols = {
    default: 4,
    [theme.breakpoints.values.xl - 1]: 3,
    [theme.breakpoints.values.lg - 1]: 2,
    [theme.breakpoints.values.md - 1]: 2,
    [theme.breakpoints.values.sm - 1]: 1,
    [theme.breakpoints.values.xs - 1]: 1,
  };

  return (
    <Masonry breakpointCols={ breakpointCols } className={ classes.masonryGrid } columnClassName={ classes.masonryGridColumn }>{
      bookstores.map(bookstore => (
        <CustomCard
          key={ bookstore.sys.id }
          name={ bookstore.fields.name }
          city={ bookstore.fields.city }
          location={ locations.find(location => location.value === bookstore.fields.location).name }
          avatar={{ text: bookstore.fields.avatarLetters, image: bookstore.fields.avatarImage?.fields?.file.url }}
          description={ bookstore.fields.description }
          facebook={ bookstore.fields.facebook }
          instagram={ bookstore.fields.instagram }
          telegram={ bookstore.fields.telegram }
          whatsapp={ bookstore.fields.whatsapp }
          mail={ bookstore.fields.mail }
          phone={ bookstore.fields.phone }
          direction={ bookstore.fields.direction }
        />
      ))
    }</Masonry>
  );
}

MasonryLayout.propTypes = {
  bookstores: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
};

export default MasonryLayout;
