import { action, thunk } from 'easy-peasy';

import { getGlobal, getBookstores } from '../../services/contenful';

const tripsModel = {
  global: {},
  bookstores: [],
  location: 0,
  locations: [
    { name: 'Todos', value: 0 },
    { name: 'San José', value: 1 },
    { name: 'Alajuela', value: 2 },
    { name: 'Cartago', value: 3 },
    { name: 'Heredia', value: 4 },
    { name: 'Guanacaste', value: 5 },
    { name: 'Puntarenas', value: 6 },
    { name: 'Limón', value: 7 },
  ],
  search: '',
  loadingGlobal: false,
  errorGlobal: '',
  loadingBookstores: false,
  errorBookstores: '',
  getGlobal: thunk(async (actions) => {
    actions.setLoadingGlobal(true);
    actions.setErrorGlobal('');
    return await getGlobal(actions);
  }),
  getBookstores: thunk(async (actions) => {
    actions.setLoadingBookstores(true);
    actions.setErrorBookstores('');
    return await getBookstores(actions);
  }),
  setGlobal: action((state, payload) => {
    state.global = payload;
  }),
  setBookstores: action((state, payload) => {
    state.bookstores = payload;
  }),
  setLocation: action((state, payload) => {
    state.location = payload;
  }),
  setSearch: action((state, payload) => {
    state.search = payload;
  }),
  setLoadingGlobal: action((state, payload) => {
    state.loadingGlobal = payload;
  }),
  setErrorGlobal: action((state, payload) => {
    state.errorGlobal = payload;
  }),
  setLoadingBookstores: action((state, payload) => {
    state.loadingBookstores = payload;
  }),
  setErrorBookstores: action((state, payload) => {
    state.errorBookstores = payload;
  }),
}

export default tripsModel;
