import { createStore } from 'easy-peasy';

import globalModel from './models/global';
import bookstoreModel from './models/bookstore';

const storeModel = {
  global: globalModel,
  bookstore: bookstoreModel,
};

const store = createStore(storeModel);

export default store;
