import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Link from '@material-ui/core/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import TelegramIcon from '@material-ui/icons/Telegram';
import Typography from '@material-ui/core/Typography';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import MaterialTable from 'material-table';

import { trackBookstore } from '../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    ...(theme.palette.type === 'light' && { border: '1px solid #E8E8E8' }),
    borderRadius: '8px',
    width: '100%',
    '& .MuiTableCell-head': {
      backgroundColor: 'transparent',
      zIndex: 0,
      '&:nth-child(5)': {
        paddingRight: theme.spacing(2),
      },
    },
    '& .MuiTableCell-body': {
      color: `${ theme.palette.text.secondary } !important`,
      '&:nth-child(2)': {
        [theme.breakpoints.down('md')]: {
          minWidth: '25vw',
        },
        [theme.breakpoints.down('xs')]: {
          minWidth: '50vw',
        },
      },
      '&:nth-child(4)': {
        maxWidth: '20vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      '&:nth-child(5)': {
        '& > div': {
          justifyContent: 'flex-end',
        },
      },
    },
  },
  actionsMenu: {
    '& .MuiList-root': {
      borderRadius: '8px',
    },
    '& a': {
      color: theme.palette.text.primary,
      display: 'block',
      textDecoration: 'none !important',
    },
  },
}));

const ListLayout = (props) => {
  const classes = useStyles();
  const { bookstores, locations } = props;

  const columns = [
    {
      field: 'avatar',
      sorting: false,
      render: rowData => <Avatar src={ rowData.avatar.image } className={ classes.avatar } alt={`Logo de ${ rowData.bookstore }`}>{ rowData.avatar.text }</Avatar>
    },
    { title: 'Librería', field: 'bookstore' },
    { title: 'Ubicación', field: 'location' },
    { title: 'Descripción', field: 'description', sorting: false },
  ];

  const data = bookstores.map(bookstore => ({
    avatar: { text: bookstore.fields.avatarLetters, image: bookstore.fields.avatarImage?.fields?.file.url },
    bookstore: bookstore.fields.name,
    location: locations.find(location => location.value === bookstore.fields.location).name,
    description: bookstore.fields.description,
    social: {
      facebook: bookstore.fields.facebook,
      instagram: bookstore.fields.instagram,
      telegram: bookstore.fields.telegram,
      whatsapp: bookstore.fields.whatsapp,
      mail: bookstore.fields.mail,
      phone: bookstore.fields.phone,
      direction: bookstore.fields.direction,
    },
  }));

  const [table, setTable] = React.useState({ columns, data });

  if (JSON.stringify(data) !== JSON.stringify(table.data)) {
    setTable({ columns, data });
  }

  const initialActionsMenuState = { mouseX: null, mouseY: null, name: '', social: { facebook: false, instagram: false, telegram: false, whatsapp: false, mail: false, phone: false, direction: false } };
  const [actionsMenu, setActionsMenu] = React.useState(initialActionsMenuState);
  const { facebook, instagram, telegram, whatsapp, mail, phone, direction } = actionsMenu.social;

  const handleActionsMenuClick = (event, payload) => {
    setActionsMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4, name: payload.name, social: payload.social });
  };

  const handleActionsMenuClose = () => {
    setActionsMenu(initialActionsMenuState);
  };

  const handleActionsMenuItemClick = (bookstoreName, social) => {
    handleActionsMenuClose();
    trackBookstore(bookstoreName, social);
  };

  return <React.Fragment>
    <MaterialTable
      columns={ table.columns }
      data={ table.data }
      components={{
        Container: props => (
          <div className={ classes.root } { ...props }>
          </div>
        ),
      }}
      options={{
        toolbar: false,
        draggable: false,
        paging: false,
        actionsColumnIndex: -1,
      }}
      localization={{
        header: {
          actions: 'Acciones'
        },
      }}
      actions={[
        {
          icon: MoreVertIcon,
          tooltip: 'Ver acciones',
          onClick: (event, rowData) => {
            handleActionsMenuClick(event, { name: rowData.bookstore, social: rowData.social });
          }
        }
      ]}
    />

    <Menu
      id="actions-menu"
      className={ classes.actionsMenu }
      keepMounted
      open={ actionsMenu.mouseY !== null }
      onClose={ handleActionsMenuClose }
      anchorReference="anchorPosition"
      anchorPosition={
        actionsMenu.mouseY !== null && actionsMenu.mouseX !== null
          ? { top: actionsMenu.mouseY, left: actionsMenu.mouseX }
          : undefined
      }
    >
      { facebook ? <Link href={`https://www.facebook.com/${ facebook }`} target="_blank" onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Facebook') }><MenuItem>
        <ListItemIcon><FacebookIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">Facebook</Typography>
      </MenuItem></Link> : null }
      { instagram ? <Link href={`https://instagram.com/${ instagram }`} target="_blank" onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Instagram') }><MenuItem>
        <ListItemIcon><InstagramIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">Instagram</Typography>
      </MenuItem></Link> : null }
      { telegram ? <Link href={`https://t.me/${ telegram }`} target="_blank" onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Telegram') }><MenuItem>
        <ListItemIcon><TelegramIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">Telegram</Typography>
      </MenuItem></Link> : null }
      { whatsapp ? <Link href={`https://api.whatsapp.com/send?phone=+506 ${ whatsapp }`} target="_blank" onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Whatsapp') }><MenuItem>
        <ListItemIcon><WhatsAppIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">WhatsApp</Typography>
      </MenuItem></Link> : null }
      { mail ? <Link href={`mailto:${ mail }`} onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Mail') }><MenuItem>
        <ListItemIcon><MailOutlineIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">Correo Electrónico</Typography>
      </MenuItem></Link> : null }
      { phone ? <Link href={`tel:${ phone }`} onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Phone') }><MenuItem>
        <ListItemIcon><PhoneIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">Teléfono</Typography>
      </MenuItem></Link> : null }
      { direction ? <Link href={`${ direction }`} target="_blank" onClick={ () => handleActionsMenuItemClick(actionsMenu.name, 'Direction') }><MenuItem>
        <ListItemIcon><RoomIcon fontSize="small" /></ListItemIcon>
        <Typography variant="inherit">Dirección</Typography>
      </MenuItem></Link> : null }
    </Menu>
  </React.Fragment>;
}

ListLayout.propTypes = {
  bookstores: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
};

export default ListLayout;
