import React from 'react';

import { useStoreActions } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import {  gaEvent, debounce } from '../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '138px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
  },
}));

const Search = () => {
  const classes = useStyles();

  const setSearch = useStoreActions(actions => actions.bookstore.setSearch);

  const onSearch = debounce((value) => {
    value.length >= 3 && gaEvent('User', `Searched for "${ value }"`);
    setSearch(value);
  }, 250);

  return (
    <TextField
      className={ classes.root }
      placeholder="Buscar librería"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={ (event) => onSearch(event.target.value) }
    />
  );
}

export default Search;
