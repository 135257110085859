import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import ReactGA from 'react-ga';

import { StoreProvider } from 'easy-peasy';
import store from './store/store';

import App from './App';

import * as serviceWorker from './serviceWorker';

import { setDefaultLanguage, setDefaultTranslations } from 'react-multi-lang';
import en from './translations/en.json';
import es from './translations/es.json';

setDefaultTranslations({en, es});
setDefaultLanguage( localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'es' );

ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.pageview('/homepage');

ReactDOM.render(
  <StoreProvider store={ store }>
    <Router>
      <App />
    </Router>
  </StoreProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
