import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import FacebookIcon from '@material-ui/icons/Facebook';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import TelegramIcon from '@material-ui/icons/Telegram';
import Typography from '@material-ui/core/Typography';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { trackBookstore } from '../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: props => props.notFound ? '18px' : 0,
    paddingTop: props => props.notFound ? '15px' : 0,
    position: 'relative',
    width: '100%',
    '& .MuiCardContent-root': {
      paddingBottom: 0,
    },
  },
  avatar: {
    height: theme.spacing(6),
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    width: theme.spacing(6),
  },
  header: {
    maxWidth: `calc(100% - ${ theme.spacing(8) }px)`
  },
}));

const CustomCard = (props) => {
  const classes = useStyles(props);
  const { name, avatar, city, location, description, facebook, instagram, telegram, whatsapp, mail, phone, direction, notFound } = props;

  return (
    <Card className={ classes.root }>
      <CardContent>
        { avatar ? <React.Fragment>
          <Avatar src={ avatar.image } className={ classes.avatar } alt={`Logo de ${ name }`}>{ avatar.text }</Avatar>
          <div className={ classes.header }>
            <Typography variant="overline" gutterBottom>{`${ city ? `${ city }, ` : '' }${ location }`}</Typography>
            <Typography variant="h5" component="h2" gutterBottom>{ name }</Typography>
          </div>
        </React.Fragment> : null }
        <Typography color="textSecondary" gutterBottom>{ description }</Typography>
      </CardContent>
      { !notFound ? <CardActions>
        { facebook ? <IconButton href={`https://www.facebook.com/${ facebook }`} target="_blank" onClick={ () => trackBookstore(name, 'Facebook') } aria-label="Facebook">
          <FacebookIcon />
        </IconButton> : null }
        { instagram ? <IconButton href={`https://instagram.com/${ instagram }`} target="_blank" onClick={ () => trackBookstore(name, 'Instagram') } aria-label="Instagram">
          <InstagramIcon />
        </IconButton> : null }
        { telegram ? <IconButton href={`https://t.me/${ telegram }`} target="_blank" onClick={ () => trackBookstore(name, 'Telegram') } aria-label="Telegram">
          <TelegramIcon />
        </IconButton> : null }
        { whatsapp ? <IconButton href={`https://api.whatsapp.com/send?phone=+506 ${ whatsapp }`} target="_blank" onClick={ () => trackBookstore(name, 'WhatsApp') } aria-label="WhatsApp">
          <WhatsAppIcon />
        </IconButton> : null }
        { mail ? <IconButton href={`mailto:${ mail }`} onClick={ () => trackBookstore(name, 'Mail') } aria-label="Mail">
          <MailOutlineIcon />
        </IconButton> : null }
        { phone ? <IconButton href={`tel:${ phone }`} onClick={ () => trackBookstore(name, 'Phone') } aria-label="Phone">
          <PhoneIcon />
        </IconButton> : null }
        { direction ? <IconButton href={`${ direction }`} target="_blank" onClick={ () => trackBookstore(name, 'Direction') } aria-label="Direction">
          <RoomIcon />
        </IconButton> : null }
      </CardActions> : null }
    </Card>
  );
}

CustomCard.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.exact({
    text: PropTypes.string,
    image: PropTypes.string,
  }),
  city: PropTypes.string,
  location: PropTypes.string,
  description: PropTypes.string.isRequired,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  telegram: PropTypes.string,
  whatsapp: PropTypes.string,
  mail: PropTypes.string,
  phone: PropTypes.number,
  direction: PropTypes.string,
  notFound: PropTypes.bool
};

export default CustomCard;
