import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions } from 'easy-peasy';

import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import { MENU_WIDTH, gaEvent } from '../shared/utilities';

import Search from './Search';

import LogoIcon from '../icons/logo';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1,
    position: 'relative',
    '& .backdrop': {
      display: 'none',
    },
    '& .menu': {
      backgroundColor: theme.palette.background.paper,
      borderBottomRightRadius: '8px',
      borderTopRightRadius: '8px',
      height: '100vh',
      left: `-${ MENU_WIDTH }`,
      overflow: 'auto',
      position: 'fixed',
      top: 0,
      transition: 'left .3s ease',
      width: MENU_WIDTH,
      '& hr': {
        borderColor: '#E8E8E8',
        borderStyle: 'solid',
        maxWidth: `calc(100% - ${ theme.spacing(6) }px)`,
      },
      [theme.breakpoints.up('md')]: {
        left: 0,
      },
    },
    '&--open .backdrop': {
      backgroundColor: theme.palette.text.disabled,
      display: 'block',
      height: '100%',
      left: 0,
      position: 'fixed',
      top: 0,
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    '&--open .menu': {
      left: 0,
    },
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '56px',
    justifyContent: 'flex-end',
    left: 0,
    padding: theme.spacing(0, 3),
    position: 'fixed',
    top: 0,
    transition: 'all .5s ease',
    width: '100%',
    '& .MuiIconButton-root': {
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
    },
    '&--unpinned': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
      '& ~ .menu': {
        borderTopRightRadius: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      left: MENU_WIDTH,
      width: `calc( 100% - ${ MENU_WIDTH } )`,
    },
  },
  locationBtn: {
    borderRadius: '80px',
    fontWeight: 400,
    left: theme.spacing(3),
    position: 'absolute',
    textTransform: 'none',
    top: '10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  close: {
    right: '6px',
    position: 'absolute',
    top: '6px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  srOnly: {
    border: 0,
    clip: 'rect(0,0,0,0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  logo: {
    color: theme.palette.type === 'light' ? theme.palette.secondary.dark : theme.palette.common.white,
    height: '116px',
    marginBottom: '15px',
    marginLeft: '50px',
    marginTop: '50px',
    width: '123px',
  },
  locations: {
    margin: '30px auto 0',
    maxWidth: `calc(100% - ${ theme.spacing(6) }px)`,
    '& > span': {
      marginLeft: theme.spacing(2),
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    '& button': {
      backgroundColor: 'transparent',
      borderRadius: '80px',
      boxShadow: 'none',
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
      fontWeight: 400,
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1, 3),
      textTransform: 'none',
      width: '100%',
      '&:hover, &:focus': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.black,
      },
      '&.active': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const Menu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isUpSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));
  const [isTop, setTop] = React.useState(true);

  const { disabled } = props;

  const menuOpen = useStoreState(state => state.global.menuOpen);
  const setMenuOpen = useStoreActions(actions => actions.global.setMenuOpen);

  const display = useStoreState(state => state.global.display);
  const setDisplay = useStoreActions(actions => actions.global.setDisplay);

  const globalTheme = useStoreState(state => state.global.theme);
  const setGlobalTheme = useStoreActions(actions => actions.global.setTheme);

  const location = useStoreState(state => state.bookstore.location);
  const locations = useStoreState(state => state.bookstore.locations);
  const setLocation = useStoreActions(actions => actions.bookstore.setLocation);

  const handleScroll = () => {
    window.scrollY > 0 ? setTop(false) : setTop(true);
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    }
  }, []);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  }

  const handleDisplayChange = () => {
    gaEvent('User', `Changed display to "${ display === 'masonry' ? 'list' : 'masonry' }"`);
    setDisplay(display);
  };

  const handleThemeChange = () => {
    gaEvent('User', `Changed theme to "${ globalTheme === 'light' ? 'dark' : 'light' }"`);
    setGlobalTheme(globalTheme);
  };

  const handleLocationChange = (item) => {
    if (location !== item.value) {
      gaEvent('User', `Changed location to "${ locations.find(location => location.value === item.value).name }"`);
      setLocation(item.value);
      !isUpMD && setMenuOpen(!menuOpen);
    }
  };

  return (
    <div className={ clsx(classes.root, { [`${ classes.root }--open`]: menuOpen }) }>
      <div className={ clsx(classes.header, { [`${ classes.header }--unpinned`]: !isTop }) }>
        <Button
          className={ classes.locationBtn }
          variant="contained"
          color="primary"
          startIcon={<FilterListIcon />}
          onClick={ handleMenuToggle }
          disabled={ disabled }
        >{ locations.find(item => item.value ===location).name }</Button>

        <Search />

        { isUpSM ? <IconButton onClick={ () => handleDisplayChange() } aria-label="Cambiar vista">
          { display === 'list' ? <ViewModuleIcon /> : <ViewListIcon /> }
        </IconButton> : null }

        <IconButton onClick={ () => handleThemeChange() } aria-label="Cambiar tema">
          { globalTheme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon /> }
        </IconButton>
      </div>

      <div className="backdrop" onClick={ handleMenuToggle }></div>

      <div className="menu">
        <IconButton className={ classes.close } onClick={ handleMenuToggle } aria-label="Cerrar" aria-hidden={ !menuOpen && !isUpMD } tabIndex={ !menuOpen && !isUpMD ? -1 : 0 }>
          <CloseIcon  />
        </IconButton>
        <h1>
          <span className={ classes.srOnly }>Releyendo, la guía de librerías / compra y venta de libros nuevos y usados.</span>
          <LogoIcon className={ classes.logo } />
        </h1>
        <hr />
        <div className={ classes.locations }>
          <Typography variant="overline" display="block" gutterBottom>Ubicación</Typography>
          <ul>
            { locations.map(item => <li key={ item.value }><Button
              variant="contained"
              className={ clsx({ 'active': location === item.value, }) }
              onClick={ () => handleLocationChange(item) }
              disabled={ disabled }
              aria-hidden={ !menuOpen && !isUpMD }
              tabIndex={ !menuOpen && !isUpMD ? -1 : 0 }
            >{ item.name }</Button></li>) }
          </ul>
        </div>
      </div>
    </div>
  );
}

Menu.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default Menu;
