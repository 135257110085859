import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  card: {
  }
}));

const Ticket = props => {
  const classes = useStyles();
  const { length, height, grid } = props;

  return (
    [...Array(length).keys()].map((_, index) => (
      grid ? <Grid key={ index } item xs={ grid.xs } sm={ grid.sm } md={ grid.md } lg={ grid.lg } xl={ grid.xl }><Skeleton key={ index } className={ classes.card } variant="rect" height={ height } /></Grid> :
      <Skeleton key={ index } className={ classes.card } variant="rect" height={ height } />
    ))
  );
}

Ticket.propTypes = {
  length: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  grid: PropTypes.object,
};

export default Ticket;
