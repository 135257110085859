import React from 'react';

import { useStoreState, useStoreActions } from 'easy-peasy';

import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { MENU_WIDTH } from '../shared/utilities';

import Menu from '../components/Menu';
import CustomCard from '../components/CustomCard';
import LoadingCard from '../components/LoadingCard';
import MasonryLayout from '../components/MasonryLayout';
import ListLayout from '../components/ListLayout';

// import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    padding: `64px ${ theme.spacing(4) }px`,
    [theme.breakpoints.up('md')]: {
      maxWidth: `calc(100vw - ${ MENU_WIDTH })`,
    },
    '&--open': {
      maxHeight: '100vh',
      overflow: 'hidden',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isUpSM = useMediaQuery(theme.breakpoints.up('sm'));
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const menuOpen = useStoreState(state => state.global.menuOpen);

  const display = useStoreState(state => state.global.display);

  // const global = useStoreState(state => state.bookstore.global);
  // const loadingGlobal = useStoreState(state => state.bookstore.loadingGlobal);
  // const errorGlobal = useStoreState(state => state.bookstore.errorGlobal);
  // const getGlobal = useStoreActions(actions => actions.bookstore.getGlobal);

  const bookstores = useStoreState(state => state.bookstore.bookstores);
  const loadingBookstores = useStoreState(state => state.bookstore.loadingBookstores);
  // const errorBookstores = useStoreState(state => state.bookstore.errorBookstores);
  const getBookstores = useStoreActions(actions => actions.bookstore.getBookstores);

  const location = useStoreState(state => state.bookstore.location);
  const locations = useStoreState(state => state.bookstore.locations);

  const search = useStoreState(state => state.bookstore.search);

  const formatString = string => string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  React.useEffect(() => {
    // getGlobal();
    getBookstores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredBookstores = bookstores
                              .filter(bookstore => location === 0 ? true : bookstore.fields.location === location) // Location
                              .filter(bookstore => !search ? true : (
                                formatString(bookstore.fields.name).includes(formatString(search)) ||
                                formatString(bookstore.fields.description).includes(formatString(search)) ||
                                formatString(locations.find(location => location.value === bookstore.fields.location).name).includes(formatString(search))
                              ) ) // Search

  // const t = useTranslation();

  return (
    <React.Fragment>
      {/* <Alert className={ clsx(classes.alert, classes.header)} icon={ false } severity="success">{ loadingGlobal ? 'Cargando datos...' : global.header }</Alert> */}
      <div className={ clsx(classes.root, { [`${ classes.root }--open`]: menuOpen && !isUpMD })}>

        <Menu disabled={ loadingBookstores } />

        <Grid container spacing={ 2 }>
          { loadingBookstores ? (
            display === 'list' && isUpSM ? <LoadingCard grid={{ xs: 12 }} length={ 5 } height={ 80 } /> :
                                            <LoadingCard grid={{ xs: 12, sm: 6, lg: 4, xl: 3 }} length={ 5 } height={ 300 } />
          ) :
            filteredBookstores.length ? (
              display === 'list' && isUpSM ? <ListLayout bookstores={ filteredBookstores } locations={ locations } /> :
                                              <MasonryLayout bookstores={ filteredBookstores } locations={ locations } />
            ) : (
            <Grid item xs={ 12 }><CustomCard description="No se encontraron resultados." notFound /></Grid>
          ) }
        </Grid>
      </div>
      {/* <Alert className={ clsx(classes.alert, classes.footer)} icon={ false } severity="success">{ loadingGlobal ? 'Cargando datos...' : global.footer }</Alert> */}
    </React.Fragment>
  );
}

export default Home;
