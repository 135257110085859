import { action } from 'easy-peasy';

import { DEFAULT_SNACKBAR } from '../../shared/utilities';

const globalModel = {
  title: '',
  snackbar: DEFAULT_SNACKBAR,
  menuOpen: false,
  display: localStorage.getItem('display') === 'list' ? 'list' : 'masonry',
  theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
  storeTitle: action((state, payload) => {
    state.title = payload;
  }),
  setSnackbar: action((state, payload) => {
    state.snackbar = payload;
  }),
  setMenuOpen: action((state, payload) => {
    state.menuOpen = payload;
  }),
  setDisplay: action((state, payload) => {
    const display = payload === 'masonry' ? 'list' : 'masonry';
    state.display = display ;
    localStorage.setItem('display', display);
  }),
  setTheme: action((state, payload) => {
    const theme = payload === 'light' ? 'dark' : 'light';
    state.theme = theme ;
    localStorage.setItem('theme', theme);
  }),
}

export default globalModel;
