import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#E8F5E9',
      main: '#007945',
      dark: '#005430',
      contrastText:  '#fff',
    },
    secondary: {
      light: '#F1EFE9',
      main: '#FFC400',
      dark: '#674800',
      contrastText:  '#000',
    },
    background: {
      default: '#f8f7f4',
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#E8F5E9',
      main: '#007945',
      dark: '#005430',
      contrastText:  '#fff',
    },
    secondary: {
      light: '#F1EFE9',
      main: '#FFC400',
      dark: '#674800',
      contrastText:  '#000',
    },
    background: {
      default: '#303030',
    },
  },
});
