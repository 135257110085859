import { createClient } from 'contentful';

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN
});

export const getGlobal = (actions) => client
.getEntry(process.env.REACT_APP_CONTENTFUL_GLOBAL)
.then(entry => {
  actions.setGlobal(entry.fields);
  actions.setLoadingGlobal(false);
})
.catch(err => {
  console.log(err);
  actions.setLoadingGlobal(false);
  actions.setErrorGlobal(err);
});

export const getBookstores = (actions) => client
.getEntries({
  'content_type': 'bookstore',
  order: 'fields.name',
})
.then(entries => {
  actions.setBookstores(entries.items);
  actions.setLoadingBookstores(false);
})
.catch(err => {
  console.log(err);
  actions.setLoadingBookstores(false);
  actions.setErrorBookstores(err);
});
