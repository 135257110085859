import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";

import { useStoreState, useStoreActions } from 'easy-peasy';

import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { lightTheme, darkTheme } from './material/theme';

import Home from './pages/Home';

import { DEFAULT_SNACKBAR } from './shared/utilities';

// import { useTranslation } from 'react-multi-lang';

function Alert(props) {
  return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

function App(props) {

  const globalTheme = useStoreState(state => state.global.theme);

  const snackbar = useStoreState(state => state.global.snackbar);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(DEFAULT_SNACKBAR);
  };

  // const t = useTranslation();

  return (
    <ThemeProvider theme={ globalTheme === 'dark' ? darkTheme : lightTheme }>
      <CssBaseline />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>

      <Snackbar open={ snackbar.show } autoHideDuration={ snackbar.autoHideDuration } transitionDuration={ 0 } onClose={ handleSnackbarClose }>
        <Alert onClose={ handleSnackbarClose } severity={ snackbar.severity }>
          { snackbar.message }
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}

export default withRouter(App);
